<template>
    
    <!-- <Vue3DraggableResizable
        :id="'box' + id" 
        :class="(objType === 'fullSignature' && signSrc !== null ? 'boxSign ' : (objType === 'initial' && iniSrc !== null ? 'boxInitial ' : (objType === 'stamp' && staSrc !== null ? 'boxStamp ' : (objType === 'comboSignature' && signSrc !== null ? 'boxCombo ' : (objType === 'fullSignature' ? 'boxSignDef ' : (objType === 'initial' ? 'boxInitialDef ' : (objType === 'stamp' ? 'boxStampDef ' : ( objType === 'comboSignature' ? 'boxComboDef ' : 'boxDisabled ' )))))))) + boxClass + 'Bg'"
        :initW="w2"
        :initH="h2"
        v-model:x="x"
        v-model:y="y" 
        v-model:w="w2"
        v-model:h="h2"
        :disabledX="true"
        :disabledY="true"
        :parent="true"
        :draggable="true"
        :resizable="objType === 'fullSignature' || objType === 'initial' || objType === 'stamp' ? true : false"
        @resizing="handleResizeEnd"
        :lockAspectRatio="objType === 'fullSignature' || objType === 'initial' || objType === 'stamp' ? true : false" 
        style="z-index: 2"
        > -->
        <!-- NOTE: must set false for disabledX & disabledY, to remain same position after scale -->
        <!-- :disabledX="false"
        :disabledY="false" -->

    <Vue3DraggableResizable
        :id="'box' + id" 
        :class="boxClass2" 
        :initW="w2"
        :initH="h2"
        v-model:x="x2"
        v-model:y="y2" 
        v-model:w="w2"
        v-model:h="h2"
        :parent="true"
        :draggable="isResizeable"
        :resizable="isResizeable"
        @resizing="handleResizeEnd"
        @drag-start="handleDragEnd"
        :lockAspectRatio="isResizeable" 
        style="z-index: 2"
        :key="randKey"
    >

    
    <div v-if="objType === 'name'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Name</span>
    </div>

    <div v-if="objType === 'email'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Email</span>
    </div>

    <div v-if="objType === 'idPassport'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">ID/Passport</span>
    </div>

    <div v-if="objType === 'textbox'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="font" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight+ '; font-style: ' + fontStyle">Textbox</span>
    </div>

    <div v-if="objType === 'textarea'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="bars" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Textarea</span>
    </div>

    <div v-if="objType === 'checkbox'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="check-square" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Checkbox</span>
    </div>

    <div v-if="objType === 'dateSigned'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="calendar-alt" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" />
        <span class="p-0 align-top">Date Signed</span>
        <!-- <VueDatePicker v-model="date" model-type="yyyy-MM-dd" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" :enable-time-picker="false" auto-apply></VueDatePicker> -->
    </div>

    <div v-if="objType === 'fullSignature'">
        <div v-if="signSrc !== null" class="text-center" >
            <ImgSign :id="signSrc" :width="signW" :height="signH" label="signature" :key="signW"/>
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="signature" size="lg" class="me-1 ps-0 align-top" style="cursor: pointer" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Full Signature</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>

    <div v-if="objType === 'stamp'" >
        <div v-if="staSrc !== null" class="text-center">
            <ImgSign :id="staSrc" :width="staW" :height="staH" label="stamp"/>
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="stamp" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Stamp</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>

    <div v-if="objType === 'initial'">
        <div v-if="iniSrc !== null" class="text-center">
            <ImgSign :id="iniSrc" label="initial" :width="iniW" :height="iniH" />
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="pen-nib" class="me-1 ps-0 align-top" style="margin-top: 0px" />
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Initial</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>

    <div v-if="objType === 'comboSignature'" class="p-0">
        <div :style="'height: ' + comboImgHeight + 'px'">
            <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 5">
                <fa icon="signature" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" />
                <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + '; cursor: pointer'">Signature</span>
            </div>

            <div v-if="signSrc !== null" data-bs-toggle="modal" :data-bs-target="'#' + mdl">

                <table v-if="comboStructure === 'sign'" width="100%" class="mb-2 text-center" align="center" border="0" style="position: absolute; z-index: 4; top: 0">
                    <tr>
                        <td class="border p-0" :class="boxClass + 'Bg'">
                            <ImgSign :id="signSrc" :width="w2" :height="comboImgHeight" label="signature" :style="'max-height: ' + comboImgHeight + 'px; max-width: ' + w2 + 'px; width: auto; height: ' + comboImgHeight + 'px; display: block'" />
                        </td>
                    </tr>
                </table>
              
                <table v-if="comboStructure === 'signstaoverlap'" width="100%" class="mb-2" align="center" border="0" style="position: absolute; z-index: 4; top: 0">
                    <tr>
                        <td>
                            <div style="position: relative; width: 100%">
                                <div style="position: absolute; width: 100%; top: 0; right: 0; opacity: 0.5" class="border p-0" :class="boxClass + 'Bg'">
                                    <ImgSign :id="staSrc" :width="w2" :height="comboImgHeight" class="float-end" :style="'max-height: ' + comboImgHeight + 'px; max-width: ' + w2 + 'px; width: auto; height: ' + comboImgHeight + 'px; display: block'" />
                                </div>
                                <div style="position: absolute; width: 100%; top: 0; left: 0px; z-index: 3" class="border p-0" :class="boxClass + 'Bg'">
                                    <ImgSign :id="signSrc" :width="w2" :height="comboImgHeight" class="float-start" :style="'max-height: ' + comboImgHeight + 'px; max-width: ' + w2 + 'px; width: auto; height: ' + comboImgHeight + 'px; display: block'" />
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>

        <div data-bs-toggle="modal" :data-bs-target="'#' + mdl" :style="'cursor: pointer; margin-top: ' +comboGap+ 'px; line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cFullLegalName')">{{comboData.fullLegalName}}</div>
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cDesignation')" >{{comboData.designation}}</div>
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cOrganization')">{{comboData.organization}}</div>
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cNric')">{{comboData.nric}}</div>
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cPassport')">{{comboData.passport}}</div>
            <div v-if="comboData !== undefined && data.combo && data.combo.includes('cDateSigned')">{{ func.convDateTimeFormat(new Date(), 'datetext')}}</div>
        </div>
    </div>


    <!-- Temporary hide date picker feature
    <div v-if="title === 'Date Signed'" @click="openAttr()" class="p-1">
        <fa icon="calendar-alt" size="lg" class="me-1" />
        <span>{{title}}</span>
        <VueDatePicker v-model="date" model-type="yyyy-MM-dd" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" :enable-time-picker="false" auto-apply></VueDatePicker>
        <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker>
    </div> -->

    <!-- Temporary hide click to edit feature 
    <div v-if="title === 'Textbox' && editable[id]" class="p-1">
        <table>
            <tr>
                <td><fa icon="font" size="lg" class="me-1" /></td>
                <td><input v-if="editable[id]" type="text" v-model="label" class="form-control form-control-sm" style="margin: 0px 8px; width: 150px" @blur="toggleEdit(id)" /></td>
            </tr>
        </table>
    </div>

    <div v-if="title === 'Textbox' && !editable[id]" class="p-1">
        <fa icon="font" size="lg" class="me-1" />
        <span @click="toggleEdit(id)">{{ label }}</span>
    </div> -->

    <!-- <div class="text-success small">comboGap: {{ comboGap }}</div> -->
    <!-- <div class="text-success small">comboStructure: {{ comboStructure }}</div> -->
    <!-- <div class="text-success small">box: {{w2}}, {{ h2 }}</div> -->
    <!-- <div class="text-success small">fontSize: {{fontSize}}</div> -->
    <!-- <div class="text-success small">scale: {{scale}} | {{ x2 }}, {{ y2 }}</div> -->

    </Vue3DraggableResizable>
   
</template>
 
<script>
import { ref, watch, onMounted, inject } from 'vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import funcs from '@/functions/function'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import ImgSign from '@/components/ImgSign.vue'


export default {
    props: [ 'data', 'boxClass', 'mdl', 'comboData', 'signSrc', 'iniSrc', 'staSrc', 'comboSignSrc', 'sWidth', 'sHeight', 'iWidth', 'iHeight', 'stWidth', 'stHeight', 'signRatio', 'iniRatio', 'staRatio', 'viewWidth', 'scale'],
    components: { Vue3DraggableResizable, VueDatePicker, ImgSign },
    emits: ['getResize'],
    setup (props, { emit }) {

        const func = funcs

        const signMaxW = ref(250)
        const signMaxH = ref(120)
        const iniMaxW = ref(140)
        const iniMaxH = ref(60)
        const staMaxW = ref(250)
        const staMaxH = ref(140)

        const x = ref(props.data.position.x)
        const y = ref(props.data.position.y)
        const x2 = ref(props.data.position.x)
        const y2 = ref(props.data.position.y)

        const w = ref(200)
        const h = ref(32)
        const w2 = ref(280) // ui
        const h2 = ref(38)  // ui

        const signee = ref(props.signee)
        const label = ref(props.data.label)
        const placeHolder = ref(props.data.placeholder)
        const mandatory = ref(props.data.isMandatory)
        const page = ref(props.data.position.page)
        const objType = ref(props.data.type)
        const fontFamily = ref(props.data.format.font)
        const fontSize = ref(props.data.format.size)
        const isBold = ref(props.data.format.bold)      // true/false
        const isItalic = ref(props.data.format.italic)  // true/false
        const fontWeight = ref('normal') // normal/bold
        const fontStyle = ref('normal')  // normal/italic
        const boxClass = ref(props.boxClass)
        const boxClass2 = ref(null)
        const mdlId = ref(props.mdl)
        const viewWidth = ref(props.viewWidth)
        const scale = ref(props.scale)
        const signSrc = ref(props.signSrc)
        const iniSrc = ref(props.iniSrc)
        const staSrc = ref(props.staSrc)
        const comboSignSrc = ref(props.comboSignSrc)
        const comboStructure = ref(props.data.comboStructure)
        const comboGap = ref(7) // in px aka 5pt
        const comboImgHeight = ref(133) //in px  a.k.a 100pt

        const signW = ref(props.sWidth)
        const signH = ref(props.sHeight)
        const iniW = ref(props.iWidth)
        const iniH = ref(props.iHeight)
        const staW = ref(props.stWidth)
        const staH = ref(props.stHeight)

        const isResizeable = ref(false) // for fullSignature, initial and stamp to resize image
        const randKey = ref(0)
        
        /**
         * Applicable to potrait & landscape, adjust bigger & smaller
         * Img ratio = Orig Width / Orig Height
         * 
         * New Height = New Width / Img Ratio
         * New Width = New Height * Img ratio
         * 
         */
   
        if (objType.value === 'fullSignature') {
            w.value = 250
            h.value = 120 

        } else if (objType.value === 'initial') {
            w.value = 140
            h.value = 60

        } else if (objType.value === 'stamp') {
            w.value = 250
            h.value = 140

        } else if (objType.value === 'comboSignature') {
            w.value = props.data.width === 0 ? 250 : props.data.width
            h.value = props.data.height === 0 ? 300 : props.data.height

        } else if (objType.value === 'textarea') {
            w.value = props.data.width
            h.value = props.data.height

        } else {
            if (props.data.width !== 0) {
                w.value = props.data.width
            }
            if (props.data.height !== 0) {
                h.value = props.data.height
            }
        }

        if (fontFamily.value === undefined) {
            fontFamily.value = 'arial'
        }

        if (fontSize.value === undefined) {
            fontSize.value = 14
        }

        if (isBold.value === true || isBold.value === 'true') {
            fontWeight.value = 'bold'
        } else {
            fontWeight.value = 'normal'
        }
  
        if (isItalic.value === true || isItalic.value === 'true') {
            fontStyle.value = 'italic'
        } else {
            fontStyle.value = 'normal'
        }

        const id = ref(props.data.id)
        const editable = ref([])
        const mdlBoxId = ref(null)

        const handleDragEnd = () => {
            // disabled img to change coordiate when resize, as need to set draggable = true when activate resize
            x.value = props.data.position.x * scale.value
            y.value = props.data.position.y * scale.value
        }

        const toggleEdit = (id) => {
            editable.value[id] = !(editable.value[id] === true ? true : false)
        }

        const resizeImg = async (imgRatio, imgW, imgH, boxW, boxH) => {
            // console.info('- - resizeIMG', imgRatio.toFixed(2), '|',imgW, imgH, 'box', boxW, boxH)
            if (imgW > boxW) {
                imgW = boxW
                imgH = boxW/imgRatio

            } else if (imgH > boxH) {
                imgH = boxH
                imgW = boxH*imgRatio
            }

            signW.value = imgW
            signH.value = imgH

            emit('getResize', objType.value, id.value, signW.value, signH.value)
        }

        const handleResizeEnd = async (obj) => {
            // console.info('*** handleResizeEnd', obj.w, obj.h)

            if (objType.value === 'fullSignature') {
                resizeImg(props.signRatio, props.sWidth, props.sHeight, obj.w, obj.h)
            }

            if (objType.value === 'initial') {
                resizeImg(props.iniRatio, props.sWidth, props.sHeight, obj.w, obj.h)
            }

            if (objType.value === 'stamp') {
                resizeImg(props.staRatio, props.sWidth, props.sHeight, obj.w, obj.h)
            }
            
        }

        onMounted(async() => {
            w2.value = w.value * scale.value
            h2.value = h.value * scale.value

            x2.value = x.value * scale.value
            y2.value = y.value * scale.value

            signMaxW.value = signMaxW.value * scale.value
            signMaxH.value = signMaxH.value * scale.value
            iniMaxW.value = iniMaxW.value * scale.value
            iniMaxH.value = iniMaxH.value * scale.value
            staMaxW.value = staMaxW.value * scale.value
            staMaxH.value = staMaxH.value * scale.value

            signW.value = signW.value * scale.value
            signH.value = signH.value * scale.value
            iniW.value = iniW.value * scale.value
            iniH.value = iniH.value * scale.value
            staW.value = staW.value * scale.value
            staH.value = staH.value * scale.value

            if (objType.value === 'fullSignature' || objType.value === 'initial' || objType.value === 'stamp') {
                isResizeable.value = true

                if (objType.value === 'fullSignature' && signSrc.value !== null) {
                    boxClass2.value = 'boxSign ' + boxClass.value + 'Bg'

                } else if (objType.value === 'initial' && iniSrc.value !== null) {
                    boxClass2.value = 'boxInitial ' + boxClass.value + 'Bg'

                } else if (objType.value === 'stamp' && staSrc.value !== null) {
                    boxClass2.value = 'boxStamp ' + boxClass.value + 'Bg'

                } else if (objType.value === 'comboSignature' && signSrc.value !== null) {
                    boxClass2.value = 'boxCombo ' + boxClass.value + 'Bg'

                } else if (objType.value === 'fullSignature') {
                    boxClass2.value = 'boxSignDef ' + boxClass.value + 'Bg'
                
                } else if (objType.value === 'initial') {
                    boxClass2.value = 'boxInitialDef ' + boxClass.value + 'Bg'

                } else if (objType.value === 'stamp') {
                    boxClass2.value = 'boxStampDef ' + boxClass.value + 'Bg'

                } else if (objType.value === 'comboSignature') {
                    boxClass2.value = 'boxComboDef ' + boxClass.value + 'Bg'

                } else {
                    boxClass2.value = 'boxDisabled ' + boxClass.value + 'Bg'
                }

            } else {
                isResizeable.value = false
                boxClass2.value = 'boxDisabled ' + boxClass.value + 'Bg'
            }

            comboGap.value = comboGap.value * scale.value
            comboImgHeight.value = comboImgHeight.value * scale.value
            
            randKey.value = Math.floor(Math.random() * 1000)

            // console.info('* onmounted ' + objType.value, JSON.stringify(props.data))
            // console.info('* onmounted ' + objType.value, props.data.format.italic,'|', JSON.stringify(props.data))
            // emit('getResize', objType.value, id.value, w2.value, h2.value)
        })

        return { 
          func, x, y, x2, y2, id, w, h, signee, label, page, placeHolder, mandatory, objType, boxClass,
          fontFamily, fontSize, fontWeight, fontStyle, toggleEdit, editable, mdlBoxId, handleResizeEnd, mdlId,
          signW, signH, iniW, iniH, staW, staH, viewWidth, 
          signMaxW, signMaxH, iniMaxW, iniMaxH, staMaxW, staMaxH, 
          w2, h2, isResizeable, signSrc, iniSrc, staSrc, comboSignSrc, boxClass2, handleDragEnd, randKey, 
          comboStructure, comboImgHeight, isBold, isItalic, comboGap
        }
    }
}
</script>

<style>
</style>